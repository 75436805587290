import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import styled from 'styled-components';
import { OverviewData } from '../graphQL/overviewData';
import Layout from './Layout';
import Title, { Props as TitleProps } from './Title';
import Window from './Window';
import { v4 as uniqueId } from 'uuid';

interface Props extends OverviewData {
    title: string;
    metadata: {
        [key: string]: {
            [key: string]: {
                description?: string;
                title?: string;
                keywords: [
                    {
                        keyword: string;
                    }
                ];
                photo_url?: string;
            };
        };
    };
    archiveOrUpcoming: 'archive' | 'upcoming';
}

const ShowsOverview: FC<Props> = ({
    title,
    data,
    metadata,
    archiveOrUpcoming,
}) => {
    const { locale } = useIntl();
    let metaData;
    if (metadata[`${locale}Meta`]) {
        metaData =
            metadata[`${locale}Meta`][archiveOrUpcoming] &&
            metadata[`${locale}Meta`][archiveOrUpcoming];
    }
    const listOfKeywords =
        metaData?.keywords && metaData.keywords.length
            ? metaData?.keywords.map((el) => el.keyword)
            : undefined;

    return (
        <Layout
            title={
                metaData?.title && metaData.title !== ''
                    ? metaData.title
                    : undefined
            }
            description={
                metaData?.description && metaData.description !== ''
                    ? metaData.description
                    : undefined
            }
            keywords={listOfKeywords}
            metaImage={metaData?.photo_url}
        >
            <Window windowTitle={title} isFullScreen>
                <Container>
                    {data.allMarkdownRemark.edges.map(({ node }) => {
                        const { genericShow } = node.frontmatter;

                        if (genericShow) {
                            const showYear = genericShow.endDate
                                ? `(${new Date(
                                      genericShow.endDate
                                  ).getFullYear()})`
                                : null;

                            return (
                                <React.Fragment key={uniqueId()}>
                                    <Link to={`/${locale}${node.fields.slug}`}>
                                        <ShowTitle
                                            key={genericShow.title}
                                            variant="show"
                                        >
                                            {genericShow.title} &ndash;{' '}
                                            {genericShow.location} {showYear}
                                        </ShowTitle>
                                    </Link>
                                </React.Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}
                </Container>
            </Window>
        </Layout>
    );
};

const Container = styled.div`
    padding: 18px 16px;
`;

const ShowTitle = styled(Title)<TitleProps>`
    font-weight: ${({ theme }) => theme.fontWeights.light};
    cursor: pointer;

    :hover {
        color: ${({ theme }) => theme.colors.domas3};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        margin-top: 0;
    }
`;

export default ShowsOverview;
