import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import ShowsOverview from '../components/ShowsOverview';
import useCloseOnEsc from '../hooks/useCloseOnEsc';
import { Image } from '../components/PageWindow';

interface Props {
    data: {
        allMarkdownRemark: {
            edges: [
                {
                    node: {
                        frontmatter: {
                            [key in 'genericShow' | 'genericAM']: {
                                title: string;
                                endDate?: Date;
                                location?: string;
                                highlighted_photo?: Image;
                                photos?: [Image];
                            };
                        };
                        fields: {
                            slug: string;
                            date: Date;
                        };
                    };
                }
            ];
        };
        navigation: {
            edges: [
                {
                    node: {
                        childMarkdownRemark: {
                            frontmatter: {
                                [key: string]: {
                                    archive: string;
                                };
                            };
                        };
                    };
                }
            ];
        };
        metadata: {
            edges: [
                {
                    node: {
                        childMarkdownRemark: {
                            frontmatter: {
                                [key in 'nlMeta' | 'enMeta']: {
                                    archive: {
                                        description?: string;
                                        title?: string;
                                        keywords: [
                                            {
                                                keyword: string;
                                            }
                                        ];
                                        photo_url?: string;
                                    };
                                };
                            };
                        };
                    };
                }
            ];
        };
    };
}

const ArchiveOverview: FC<Props> = ({ data }) => {
    useCloseOnEsc({
        backToPreviousPage: true,
    });

    const { locale } = useIntl();

    const windowTitle =
        data.navigation.edges[0].node.childMarkdownRemark.frontmatter[
            `${locale}Navigation`
        ].archive;

    return (
        <ShowsOverview
            title={windowTitle}
            data={{ allMarkdownRemark: data.allMarkdownRemark }}
            metadata={
                data.metadata.edges[0].node.childMarkdownRemark.frontmatter
            }
            archiveOrUpcoming="archive"
        />
    );
};

export const query = graphql`
    query archiveShows {
        allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
            ...showOverviewData
        }

        navigation: allFile(
            filter: { absolutePath: { regex: "/navigation/i" } }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            enNavigation {
                                archive
                            }
                            nlNavigation {
                                archive
                            }
                        }
                    }
                }
            }
        }
        metadata: allFile(filter: { absolutePath: { regex: "/meta_data/i" } }) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            nlMeta {
                                archive {
                                    description
                                    title
                                    keywords {
                                        keyword
                                    }
                                    photo_url
                                }
                            }
                            enMeta {
                                archive {
                                    description
                                    title
                                    keywords {
                                        keyword
                                    }
                                    photo_url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default ArchiveOverview;
